// import moment from "moment";
import Tabulator from "tabulator-tables";
import * as XLSX from "xlsx-js-style";

export function buildExportUrl(tableTabulator) {
  let filters = tableTabulator.getFilters(true); // Получить все фильтры
  let sorters = tableTabulator.getSorters(); // Получить все сортировки

  let params = new URLSearchParams();

  console.log('filters:', filters);
  // Добавить фильтры в параметры
  filters.forEach((filter, index) => {
    if (Array.isArray(filter.value)) {
      filter.value.forEach((value, valueIndex) => {
        params.set(`filters[${index}][field]`, filter.field);
        params.set(`filters[${index}][type]`, filter.type);
        params.set(`filters[${index}][value][${valueIndex}]`, value);
      });
    } else {
      params.set(`filters[${index}][field]`, filter.field);
      params.set(`filters[${index}][type]`, filter.type);
      params.set(`filters[${index}][value]`, filter.value);
    }
  });

  // Добавить сортировки в параметры
  sorters.forEach((sorter, index) => {
    params.set(`sorters[${index}][field]`, sorter.field);
    params.set(`sorters[${index}][dir]`, sorter.dir);
  });

  const baseUrl = window.location.origin;
  const ajaxUrl = tableTabulator.getAjaxUrl();

  let url = new URL(ajaxUrl, baseUrl);
  url.search = params.toString();

  console.log('url:', url.toString());
  return url.toString() + '&export=true';
}

export function exportApplyHeaderStyle(cell) {
  cell.s = {
    fill: { patternType: "solid", fgColor: { rgb: "FDE9D9" } }, // Yellow background
    font: { bold: true }, // Bold text
    alignment: { horizontal: "center", vertical: "center", wrapText: true }, // Center aligned text with word wrap
    border: {
      top: { style: "thin", color: { rgb: "000000" } },
      bottom: { style: "thin", color: { rgb: "000000" } },
      left: { style: "thin", color: { rgb: "000000" } },
      right: { style: "thin", color: { rgb: "000000" } }
    } // Borders around the cell
  };
}

export function exportApplyRowStyle(cell) {
  cell.s = {
    // fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } }, // White background
    // font: { bold: false }, // Regular text
    alignment: { horizontal: "left", vertical: "center", wrapText: true }, // Left aligned text with word wrap
    border: {
      top: { style: "thin", color: { rgb: "000000" } },
      bottom: { style: "thin", color: { rgb: "000000" } },
      left: { style: "thin", color: { rgb: "000000" } },
      right: { style: "thin", color: { rgb: "000000" } }
    } // Borders around the cell
  };
}


export function exportTableXlsx(url, tableTabulator, title) {
  let filename = title + ".xlsx";
  let tempTable = new Tabulator("#temp-table", {
    pagination: "remote",
    paginationSize: 20,
    columns: tableTabulator.getColumns().map(column => column.getDefinition())
  });

  // Загружаем данные во временную таблицу
  tempTable.replaceData(url)
    .then(() => {
      // Выполняем скачивание

      let headerStyle = exportApplyHeaderStyle;
      let rowStyle = exportApplyRowStyle;

      tempTable.download("xlsx", filename,{
        documentProcessing: function(workbook){
          workbook.Props = { Application: "Transport",  Title: title,  Subject: title, CreatedDate: new Date() };
          let worksheet = workbook.Sheets[workbook.SheetNames[0]];
          // Apply styles to the header row
          let range = XLSX.utils.decode_range(worksheet['!ref']);

          for(let C = range.s.c; C <= range.e.c; ++C) {
            let address = XLSX.utils.encode_col(C) + "1"; // <-- 1 is the row number for the header
            if(!worksheet[address]) continue;
            headerStyle(worksheet[address]);
          }

          // Apply styles to the other rows
          for(let R = range.s.r + 1; R <= range.e.r; ++R) {
            for(let C = range.s.c; C <= range.e.c; ++C) {
              let address = XLSX.utils.encode_col(C) + (R+1); // <-- R+1 is the row number
              if(!worksheet[address]) continue;
              rowStyle(worksheet[address]);
            }
          }

          return workbook;
        }
      }, 'all');
    })
    .catch(error => console.error('Error:', error))
    .finally(() => {
      // Удаляем временную таблицу после скачивания
      tempTable.destroy();
    });
}
