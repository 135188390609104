import InfiniteScroll from "infinite-scroll"
import { ApplicationController } from "../application_controller";

export default class extends ApplicationController {
  static targets = ["date_from", "date_to", "only_attachments",
    "my_comments", "only_tasks", "content", "comments_list"]

  connect() {
    if (this.hasComments_listTarget) {
      const nextLink = this.comments_listTarget.querySelector('.comments_pagination__next');
      if (nextLink) {
        let updateSidebarStick = super.updateSidebarStick
        const infScroll = new InfiniteScroll(this.comments_listTarget, {
          // options
          path: '.comments_pagination__next',
          append: 'li',
          history: false,
          prefill: true
        });

        // infScroll.on('load', function () {
        //   updateSidebarStick()
        // });
      }
    }

  }

  //
  // Actions
  //
  editComment(event) {
    event.preventDefault();
    let url = this.data.get("edit-url");
    this.openLayoutModalMD(url);
  }

  filter(event) {
    let url = this.data.get("content-url");

    const from = this.date_fromTarget.value;
    const to = this.date_toTarget.value;
    const only_attachments = this.hasOnly_attachmentsTarget ? this.only_attachmentsTarget.checked : false;
    const my_comments = this.hasMy_commentsTarget ? this.my_commentsTarget.checked : false;
    const only_tasks = this.hasOnly_tasksTarget ? this.only_tasksTarget.checked : false;

    // query
    let params = {
      from: from,
      to: to,
      only_attachments: only_attachments,
      my_comments: my_comments,
      only_tasks: only_tasks
    }

    url += '?' + this.objectToQueryString(params);

    this.loadContent(url)
  }

  async loadContent(content_url) {
    let content_response = await fetch(content_url);
    let content = await content_response.text();
    this.contentTarget.innerHTML = content;
  }

}
